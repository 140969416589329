<template>
  <div>
    <!-- Browse File -->
    <div style="margin-top: 0.3em;">
      <b-row style="margin-bottom: 0.3em;">
        <b-col
          cols="12"
          md="6"
          class="pr-0"
        >
          <b-alert
            v-height-fade.appear
            show
            fade
            class="mb-0"
            variant="success"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              <span>{{ $t('Browse Excel File for import') }} (xlsx, xls)</span>
            </div>
          </b-alert>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-alert
            v-height-fade.appear
            show
            fade
            class="mb-0"
            variant="success"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              <span>{{ $t('Browse Image Directory for import') }} (jpg, png)</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="pr-0"
        >
          <b-form-file
            ref="fileImport"
            :placeholder="$t('Choose Excel file for import')"
            size="lg"
            accept=".xlsx, .xls"
            no-drop
            :browse-text="$t('Browse File')"
            @change="changeFileImport"
          >
            <template
              slot="file-name"
              slot-scope="{ names }"
            >
              <b-badge variant="success">
                {{ names[0] }}
              </b-badge>
              <b-badge
                v-if="names.length > 1"
                variant="dark"
                class="ml-1"
              >
                + {{ names.length - 1 }} More files
              </b-badge>
            </template>
          </b-form-file>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-file
            ref="dirImport"
            :placeholder="$t('Choose image directory for import')"
            size="lg"
            :browse-text="$t('Image Directory')"
            directory
            @change="changeDirectoryImport"
          >
            <template
              slot="file-name"
            >
              <b-badge
                variant="info"
              >
                {{ dirName }}
              </b-badge>
              <b-badge
                style="margin-left: 0.4em;"
                variant="success"
              >
                {{ fileTotal }}
              </b-badge>
            </template>
          </b-form-file>
        </b-col>
      </b-row>
    </div>

    <!-- Form Import -->
    <b-card
      v-if="rawFileImport !== null"
      style="margin-top: 0.8em;"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- form -->
        <b-form
          ref="refForm"
          class="mt-1"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetLocalForm"
        >
          <b-row>
            <!-- brand -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('Brand')"
                label-for="coupon-brand"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Brand')"
                  rules="required"
                >
                  <v-select
                    v-model="localData.brand_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="localData.brand_options"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="coupon-brand"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- merchant -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('Merchant')"
                label-for="coupon-merchant"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Merchant')"
                  rules="required"
                >
                  <v-select
                    v-model="localData.merchant_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="localData.merchant_options"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="coupon-merchant"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- start date -->
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label-for="coupon-start-date"
                :label="$t('Start Date')"
              >
                <flat-pickr
                  id="coupon-start-date"
                  v-model="localData.start_date"
                  :config="{ dateFormat: 'Y-m-d' }"
                  class="form-control"
                  :placeholder="$t('Start Date')"
                />
              </b-form-group>
            </b-col>

            <!-- stop date -->
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label-for="coupon-stop-date"
                :label="$t('Stop Date')"
              >
                <flat-pickr
                  id="coupon-stop-date"
                  v-model="localData.stop_date"
                  :config="{ dateFormat: 'Y-m-d' }"
                  class="form-control"
                  :placeholder="$t('Stop Date')"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="8">
              <b-button
                ref="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                :disabled="busy"
                @click="onSubmit"
              >
                {{ $t('Process') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click.prevent="resetLocalForm"
              >
                {{ $t('Reset') }}
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="text-right d-none d-md-block d-lg-block"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="success"
                type="button"
                class="mt-2"
                :disabled="(tableDataImport.length > 0)? false : true"
                @click.prevent="onImportCoupon"
              >
                <feather-icon
                  icon="ArrowDownCircleIcon"
                  size="14"
                />
                <span class="ml-25 align-middle"> {{ `${$t('Import')} ${$t('Coupon')}` }}</span>
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="d-lg-none d-md-none d-sm-block"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="success"
                type="button"
                class="mt-2"
                :disabled="(tableDataImport > 0)? false : true"
                @click.prevent="onImportCoupon"
              >
                <feather-icon
                  icon="ArrowDownCircleIcon"
                  size="14"
                />
                <span class="ml-25 align-middle"> {{ `${$t('Import')} ${$t('Coupon')}` }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-overlay
        :show="busy"
        no-wrap
        @shown="onShown"
        @hidden="onHidden"
      >
        <template #overlay>
          <div
            v-if="processing"
            class="text-center p-2 bg-primary text-light rounded"
          >
            <feather-icon
              icon="UploadCloudIcon"
              size="20"
            />
            <div class="mb-2">
              {{ `${$t('Processing')}...` }}
            </div>
            <b-progress
              min="1"
              max="10"
              :value="counter"
              variant="success"
              height="6px"
              class="mx-n1"
            />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <b-card-text
              class="font-weight-bolder"
            >
              {{ $t('Are you sure?') }}
            </b-card-text>
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mr-3"
                @click="onCancel"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                ref="dialogOk"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                @click="onOK"
              >
                {{ $t('Ok') }}
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      style="margin-top:1em;"
    >
      <b-overlay
        :show="showTable"
        :variant="variantTable"
        :opacity="opacityTable"
        :blur="blurTable"
        rounded="sm"
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ `${$t('Total')} ${tableDataImport.length} ${$t('Entries')}` }}</label>
              <label class="pl-1 pr-1"><feather-icon icon="ChevronRightIcon" /></label>
              <label>{{ `${$t('Import')}` }}</label>
              <label style="padding-left: 0.4em; padding-right: 1em;"><feather-icon icon="ChevronsRightIcon" /></label>
              <label>{{ `${$t('Done')} ${importCountOk}/${importCountTotal} ${$t('Entries')}` }}</label>
              <label class="pl-1 pr-1"><feather-icon icon="ChevronRightIcon" /></label>
              <label>{{ `${$t('Error')} ${importCountError} ${$t('Entries')}` }}</label>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refCouponImportListTable"
          :items="tableDataImport"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          hover
          empty-text="No matching records found"
          class="position-relative"
        >
          <!-- Column: No -->
          <template #cell(no)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.no }}</span>
            </div>
          </template>

          <!-- Column: Code/Redeem -->
          <template #cell(code_redeem)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.code }}</span>
            </div>
            <div>
              <b-badge
                pill
                :variant="(skin === 'dark')? (data.item.ref_id !== '')? 'light-danger' : 'light-info' : (data.item.ref_id !== '')? 'danger' : 'info'"
              >
                {{ (data.item.ref_id !== '')? data.item.ref_id : $t('Not Redeemed') }}
              </b-badge>
            </div>
            <div v-if="data.item.point !== ''">
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-danger` : `danger`"
              >
                {{ data.item.point }}
              </b-badge>
            </div>
            <div v-if="data.item.reference !== ''">
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-danger` : `danger`"
              >
                {{ data.item.reference }}
              </b-badge>
            </div>
            <div v-if="data.item.date_time !== ''">
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-danger` : `danger`"
              >
                {{ data.item.date_time }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Value -->
          <template #cell(value)="data">
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-success` : `success`"
            >
              ฿ {{ data.item.value }}
            </b-badge>
          </template>

          <!-- Column: Brand/Merchant -->
          <template #cell(brand_merchant)="data">
            <div>
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-info` : `info`"
                style="margin-right: 0.8em;"
              >
                {{ data.item.brand_id }}
              </b-badge>
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-info` : `info`"
              >
                {{ data.item.merchant_id }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Start/Stop Date -->
          <template #cell(start_stop_date)="data">
            <div style="margin-bottom: 0.8em;">
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-success` : `success`"
              >
                {{ (data.item.start_date !== '')? data.item.start_date : '-' }}
              </b-badge>
            </div>
            <div>
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-danger` : `danger`"
              >
                {{ (data.item.stop_date !== '')? data.item.stop_date : '-' }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Image -->
          <template #cell(image)="data">
            <div
              class="text-nowrap"
            >
              <span class="align-text-top">{{ data.item.image }}</span>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-${data.item.statusVariant}` : data.item.statusVariant"
              >
                {{ (data.item.status !== '')? data.item.status : $t('Wait Import') }}
              </b-badge>
            </div>
          </template>
        </b-table>

      </b-overlay>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BAlert,
  BForm,
  BFormGroup,
  BOverlay,
  BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import XLSX from 'xlsx'
import { heightFade } from '@core/directives/animations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import useAppConfig from '@core/app-config/useAppConfig'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import couponStoreModule from '../couponStoreModule'
import useCouponImportList from './useCouponImportList'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormFile,
    BAlert,
    BForm,
    BFormGroup,
    BOverlay,
    BProgress,

    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      localData: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      skin: store.state.appConfig.layout.skin,
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      selectedFormat: 'xlsx',
      rawFileImport: null,
      rawImageImport: null,
      fileImageImport: null,
      tableDataImport: [],
      header: [],
      sheetName: '',
      searchQuery: '',
      excelData: {
        header: null,
        results: null,
        meta: null,
      },
      dirName: '',
      fileTotal: '',
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      isAction: null,
      showTable: false,
      variantTable: this.skin.value,
      blurTable: '2px',
      opacityTable: 0.55,
      importCountTotal: 0,
      importCountOk: 0,
      importCountError: 0,
    }
  },
  mounted() {
    localize(this.$i18n.locale)
    this.getDefaultCoupon()
  },
  methods: {
    getDefaultCoupon() {
      store
        .dispatch('coupon-store/getDefaultCoupon', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.localData = response.data
          this.localData.start_date = ''
          this.localData.stop_date = ''
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    generateData({ header, results, meta }) {
      this.excelData.header = header
      this.excelData.results = results
      this.excelData.meta = meta
      this.loadDataInTable(this.excelData)
    },
    getHeaderRow(sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      // eslint-disable-next-line no-plusplus
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = `UNKNOWN ${C}` // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    },
    readerData(rawFile) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, {
            type: 'buffer',
            sheetStubs: true,
          })
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils
            .sheet_to_json(worksheet, { defval: '', raw: false })
          const meta = { sheetName: firstSheetName }
          this.generateData({ header, results, meta })
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    changeFileImport(e) {
      const { files } = e.target
      const rawFile = files[0]
      this.rawFileImport = null
      if (!rawFile) return
      this.rawFileImport = rawFile
    },
    uploadFileImport(file) {
      this.$refs.fileImport.value = null // fix can't select the same excel
      this.readerData(file)
    },
    changeDirectoryImport(e) {
      const { files } = e.target
      this.fileImageImport = null
      this.rawImageImport = null
      if (files.length > 0) {
        const path = files[0].webkitRelativePath
        const pathName = path.substring(0, path.lastIndexOf('/'))
        this.dirName = pathName
        this.fileTotal = `${files.length} ${this.$i18n.t('Files')}`
        const ff = []
        const fi = []
        files.forEach(file => {
          const fileName = file.name.substring(0, file.name.lastIndexOf('.'))
          if (fileName !== undefined && fileName !== '') {
            ff[fileName] = file
          }
          fi.push(file)
        })
        this.rawImageImport = ff
        this.fileImageImport = fi
      }
    },
    loadDataInTable({ results, header, meta }) {
      const reResults = []
      results.forEach(field => {
        const fd = field
        fd.brand_id = this.localData.brand_id
        fd.merchant_id = this.localData.merchant_id
        if ((fd.start_date === '' || fd.start_date === '0000-00-00') && this.localData.start_date !== '') {
          fd.start_date = this.localData.start_date
        }
        if ((fd.stop_date === '' || fd.stop_date === '0000-00-00') && this.localData.stop_date !== '') {
          fd.stop_date = this.localData.stop_date
        }
        // if (this.fileImageImport !== null) {
        //   const obj = this.fileImageImport.find(o => o.name.substring(0, o.name.lastIndexOf('.')) === fd.code)
        //   if (obj !== undefined) {
        //     // found
        //     fd.image = obj.name
        //   }
        // } else {
        //   fd.image = ''
        // }
        if (this.rawImageImport !== null) {
          if (this.rawImageImport[fd.code] !== undefined) {
            fd.image = this.rawImageImport[fd.code].name
          }
        } else {
          fd.image = ''
        }
        fd.statusVariant = 'warning'
        fd.status = this.$i18n.t('Wait Import')
        reResults.push(fd)
      })
      this.header = header
      this.tableDataImport = reResults
      this.sheetName = meta.sheetName

      this.importCountTotal = 0
      this.importCountOk = 0
      this.importCountError = 0

      const okMsg = this.$i18n.t('Process on successfully')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: okMsg,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      },
      {
        position: 'bottom-right',
      })
      this.busy = false
      this.processing = false
      this.showTable = false
      this.$swal({
        icon: 'success',
        title: this.$i18n.t('Process'),
        text: this.$i18n.t('Your data has been {action}', { action: this.$i18n.t('Process') }),
        timer: 3000,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    resetTable() {
      this.$refs.fileImport.reset()
      this.$refs.dirImport.reset()
      this.rawFileImport = null
      this.rawImageImport = null
      this.header = []
      this.tableDataImport = []
      this.sheetName = ''
    },
    resetLocalForm() {
      this.processing = false
      this.busy = true
      this.isAction = 'reset'
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
          this.isAction = 'process'
        }
      })
    },
    onImportCoupon() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
          this.isAction = 'import'
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      this.showTable = true
      if (this.isAction === 'process') {
        if (this.rawFileImport !== null) {
          this.uploadFileImport(this.rawFileImport)
        }
      } else if (this.isAction === 'reset') {
        this.getDefaultCoupon()
        this.resetTable()
        this.$refs.refFormObserver.reset()
        this.busy = false
        this.processing = false
        this.showTable = false
      } else if (this.isAction === 'import') {
        this.importCoupon()
        this.busy = false
        this.processing = false
        this.showTable = false
      }
    },
    importCoupon() {
      if (this.tableDataImport.length > 0) {
        this.importCountTotal = 0
        this.importCountOk = 0
        this.importCountError = 0
        this.tableDataImport.forEach(row => {
          this.importCountTotal += 1
          const index = this.tableDataImport.findIndex(o => o.no === row.no)
          if (index >= 0) {
            this.tableDataImport[index].statusVariant = 'info'
            this.tableDataImport[index].status = this.$i18n.t('Importing')
            this.refetchData()
          }
          store.dispatch('coupon-store/importCoupon', { data: row })
            .then(response => {
              const { id } = response.data
              let statusMessage = ''
              if (this.rawImageImport !== null) {
                if (this.rawImageImport[row.code] !== undefined) {
                  const formData = new FormData()
                  formData.append('file', this.rawImageImport[row.code])

                  store.dispatch('coupon-store/uploadImageCoupon', { id, data: formData })
                    .then(() => {
                      this.importCountOk += 1
                      statusMessage = `${this.$i18n.t('Success')} #${id}`
                      if (index >= 0) {
                        this.tableDataImport[index].statusVariant = 'success'
                        this.tableDataImport[index].status = statusMessage
                        this.refetchData()
                      }
                    })
                    .catch(err => {
                      this.importCountError += 1
                      const { resp, message } = err
                      let errorMessage = message
                      if (resp) {
                        errorMessage = this.$i18n.t(resp?.data.error)
                        if (errorMessage === '') {
                          errorMessage = this.$i18n.t(resp?.data.message)
                        }
                      }
                      statusMessage = errorMessage
                      if (index >= 0) {
                        this.tableDataImport[index].statusVariant = 'danger'
                        this.tableDataImport[index].status = statusMessage
                        this.refetchData()
                      }
                    })
                } else {
                  statusMessage = this.$i18n.t('Not found image')
                  this.importCountError += 1
                  if (index >= 0) {
                    this.tableDataImport[index].statusVariant = 'danger'
                    this.tableDataImport[index].status = statusMessage
                    this.refetchData()
                  }
                }
              } else {
                statusMessage = `${this.$i18n.t('Success')} #${id}`
                this.importCountOk += 1
                if (index >= 0) {
                  this.tableDataImport[index].statusVariant = 'success'
                  this.tableDataImport[index].status = statusMessage
                  this.refetchData()
                }
              }
            })
            .catch(error => {
              this.importCountError += 1
              const { response, message } = error
              let errorMessage = message
              if (response) {
                errorMessage = this.$i18n.t(response?.data.error)
                if (errorMessage === '') {
                  errorMessage = this.$i18n.t(response?.data.message)
                }
              }
              if (index >= 0) {
                this.tableDataImport[index].statusVariant = 'danger'
                this.tableDataImport[index].status = errorMessage
                this.refetchData()
              }
            })
        })
      } else {
        // not found
        const msg = this.$i18n.t('Not found data')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      }
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'coupon-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, couponStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      tableColumns,
      refCouponImportListTable,
      refetchData,

    } = useCouponImportList()

    const { skin } = useAppConfig()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      tableColumns,
      refCouponImportListTable,
      refetchData,

      skin,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
      .swal2-popup {
        background-color: $theme-dark-body-bg !important;
      }
  }
}
</style>
