import { ref } from '@vue/composition-api'

import { t } from '@/@core/libs/i18n/utils'

export default function useCouponImportList() {
  const refCouponImportListTable = ref(null)

  const msgCouponImportList = {
    errorfetchCoupon: t('Error fetching {module} list', t('Coupon Import')),
    no: t('No.'),
    code_redeem: `${t('Code')}/${t('Redeem')}`,
    value: t('Value'),
    brand_merchant: `${t('Brand')}/${t('Merchant')}`,
    start_stop_date: `${t('Start Date')}/${t('Stop Date')}`,
    image: t('Image'),
    status: t('Status'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'no', sortable: false, label: msgCouponImportList.no },
    { key: 'code_redeem', sortable: false, label: msgCouponImportList.code_redeem },
    { key: 'value', sortable: false, label: msgCouponImportList.value },
    { key: 'brand_merchant', sortable: false, label: msgCouponImportList.brand_merchant },
    { key: 'start_stop_date', sortable: false, label: msgCouponImportList.start_stop_date },
    { key: 'image', label: msgCouponImportList.image },
    { key: 'status', label: msgCouponImportList.status },
  ]

  const refetchData = () => {
    refCouponImportListTable.value.refresh()
  }

  return {
    tableColumns,
    refCouponImportListTable,

    refetchData,
  }
}
